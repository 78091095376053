<template>
  <div class="register">
    <div class="modal">
      <div class="form">
        <div class="title">嗨！欢迎注册！</div>
        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" autocomplete="off">
          <el-form-item label="" prop="mobile" class="row">
            <el-input type="text" v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="vcode" class="row">
            <el-input type="text" v-model="ruleForm.vcode" placeholder="请输入验证码"></el-input>
            <img :src="imgcodeurl" alt="验证码" ref="imgEl" @click.stop="$refs.imgEl.src = imgcodeurl + getRandom()">
          </el-form-item>
          <el-form-item label="" prop="vmcode" class="row">
            <el-input type="text" placeholder="请输入短信验证码" v-model="ruleForm.vmcode" autocomplete="new-password">
            </el-input>
            <div class="send" @click="sendCode" v-if="second <= 0">获取验证码</div>
            <div class="send active" v-else>({{ second }}秒)重新发送</div>
          </el-form-item>
          <el-form-item label="" prop="password" class="row">
            <el-input :type="showPass ? 'text' : 'password'" placeholder="请输入密码" v-model="ruleForm.password"
              autocomplete="new-password"></el-input>
            <i class="iconfont mima" :class="showPass ? 'icon-xianshimima' : 'icon-yincang'"
              @click.stop="showPass = !showPass"></i>
          </el-form-item>
          <el-form-item label="" prop="repassword" class="row">
            <el-input :type="showPass1 ? 'text' : 'password'" placeholder="再次确认密码" v-model="ruleForm.repassword">
            </el-input>
            <i class="iconfont mima" :class="showPass1 ? 'icon-xianshimima' : 'icon-yincang'"
              @click.stop="showPass1 = !showPass1"></i>
          </el-form-item>
          <div class="row" style="margin-bottom: 14px">
            <div class="submit" @click="register">马上注册</div>
          </div>
        </el-form>

        <div class="row tip"><span>注册即代表同意<i
              @click="$bus.$emit('changeShowProtocol', true)">《356编辑器用户注册协议》</i></span><span
            @click="$store.commit('watchShowLogin', true)">直接登录</span></div>
        <div class="row third">
          <p>免注册登录</p>
          <ul>
            <li @click="$store.commit('watchShowLogin', true)"><img src="@/assets/image/logindialog/weixin.png"
                alt="">微信</li>
            <li style="margin-left: 18px" @click="getQQLogin"><img src="@/assets/image/logindialog/qq.png" alt="" />QQ
            </li>
            <li @click="getWeiboLogin"><img src="@/assets/image/logindialog/weibo.png" alt="" />微博</li>
          </ul>
        </div>
      </div>
      <div class="info">
        <div class="title">扫码关注/邀请注册<span>领会员</span></div>
        <img src="@/assets/image/register_qrcode.png" alt="" class="kefu">
        <div class="tip">
          扫码关注领会员<br />
          扫码关注“365编辑器”官方微信订阅号获取最新活动资讯，更有作图神器-爱设计会员限时免费赠送！<br /><br />
          邀请注册领会员<br />
          通过好友注册成功，您和邀请人均将自动获得365编辑器VIP会员3天使用权限。<br />
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { Login } from "@/config/axios/api";

export default {
  name: "Info",
  data () {
    var validateMobile = (rule, value, callback) => {
      if (!this.staticFunc.checkMobile(value)) {
        callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        var reg = /^[A-Za-z0-9]{6,16}$/;
        if (!reg.test(this.ruleForm.password)) {
          callback(new Error("新密码须由6-16位数字或大小写字母组成"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      registerLoading: false,  //是否正在注册
      showPass: false,
      showPass1: false,
      second: 0,
      imgcodeurl: (process.env.NODE_ENV === "development" ? '/api' : process.env.VUE_APP_BaseUrl) + this.getImgCodeUrl + '?_=',
      ruleForm: {
        mobile: "",
        vmcode: "", // 手机验证码
        vcode: "",   //图片验证码
        password: "",
        repassword: "",
        is_sc: 1,
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        vcode: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        vmcode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        repassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  components: {

  },
  created () {
    if (this.staticFunc.isLogin()) {
      // 如果已登陆直接去首页
      window.location.replace('/')
    }
    this.initParams()

  },
  methods: {
    getRandom () {
      return 10000 + Math.round(Math.random() * (99999 - 10000))
    },
    sendCode () {
      let validateList = [];
      this.$refs['ruleForm'].validateField(['mobile', 'vcode'], (valid) => {
        validateList.push(valid)
      })
      if (validateList.every((item) => item === "")) {
        Login.registerSms({ mobile: this.ruleForm.mobile, vcode: this.ruleForm.vcode }).then(res => {
          if (res.code == 'S') {
            this.second = 60;
            this.$message.success('发送成功')
            let smsInterval = setInterval(() => {
              this.second--;
              if (this.second <= 0) {
                clearInterval(smsInterval)
              }
            }, 1000)
          } else {
            this.$refs.imgEl.src = this.imgcodeurl + this.getRandom()
          }
        })
      }

    },
    register () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.registerLoading) return;
          this.registerLoading = true;
          Login.register(this.ruleForm).then(res => {
            this.registerLoading = false;
            if (res.code == 'S') {
              this.$message.success('注册成功')
              location.href = process.env.VUE_APP_BASELogout;
            }
          })
        }
      })
    },
    initParams () {
      var url = window.location.href;
      window.localStorage.setItem('url', url)

      this.login_origin = localStorage.getItem('login_origin') ? localStorage.getItem('login_origin') : "";
      this.login_keyword = localStorage.getItem('login_keyword') ? localStorage.getItem('login_keyword') : "";
      this.login_url = localStorage.getItem('login_url') ? localStorage.getItem('login_url') : "";

      if (this.login_url == "") {
        localStorage.setItem('login_url', window.location.href);
        if (this.$route.query.f) {
          this.login_origin = decodeURIComponent(this.$route.query.f);
          localStorage.setItem('login_origin', this.login_origin);
        }
        if (this.$route.query.k) {
          this.login_keyword = decodeURIComponent(this.$route.query.k);
          localStorage.setItem('login_keyword', this.login_keyword);
        }
      }
    },
    // QQ登录
    getQQLogin () {
      if (localStorage.getItem("isBaiduForm")) {
        localStorage.setItem("isBaiduForm", "1");
      }
      window.location.href = this.editorUrl + "/api/qq/login?is_sc=1&state=" + this.login_origin + '&keyword=' + this.login_keyword + '&url=' + decodeURIComponent(this.login_url);
    },
    // 微博登陆
    getWeiboLogin () {
      var newTab = window.open('about:blank');
      Login.weiboLogin({
        state: this.login_origin,
        keyword: this.login_keyword,
        is_sc: 1,
        url: decodeURIComponent(this.login_url)
      }).then(backData => {
        if (localStorage.getItem("isBaiduForm")) {
          localStorage.setItem("isBaiduForm", "1");
        }
        newTab.location.href = backData;
      })
    },
  }
};
</script>
<style scoped lang="scss">
.register {
  width: 100%;
  padding: 150px 0 0;
  min-height: 100vh;
  background: #F7F7F8;

  .modal {
    width: 890px;
    height: 598px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 4px;
    @include flex('', space-between);

    .form {
      padding: 36px 48px;
      width: 492px;
      flex-shrink: 0;
      border-right: 1px solid #DEDEDD;

      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2020;
        line-height: 25px;
        margin-bottom: 17px;
      }

      .row {
        width: 100%;
        margin-bottom: 18px;
        position: relative;
        @include flex(center, space-between);

        /deep/ .el-form-item__content {
          width: 100%;
          @include flex(center, space-between);
          height: 46px;

          .el-input__inner {
            width: 100%;
            height: 46px;
            border-radius: 4px;
            border: 1px solid #DEDEDD;
            padding-left: 23px;

            &:hover,
            &:focus {
              border: 1px solid $activeColor;
            }
          }
        }


        img {
          margin-left: 12px;
          width: 114px;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #EBECF1;
          cursor: pointer;
          flex-shrink: 0;
        }

        .send {
          flex-shrink: 0;
          cursor: pointer;
          margin-left: 12px;
          width: 114px;
          height: 46px;
          background: rgba(52, 171, 255, 0.04);
          border-radius: 4px;
          border: 1px solid #EBECF1;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $activeColor;
          line-height: 44px;
          text-align: center;
          transition: .3s;

          &:hover,
          &.active {
            color: #FFFFFF;
            background: $activeColor;
          }
        }

        .submit {
          width: 100%;
          height: 46px;
          cursor: pointer;
          background: #2C86FF;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 44px;
          text-align: center;
        }

        .mima {
          position: absolute;
          right: 20px;
          cursor: pointer;
          color: #333333;
          opacity: 0.4;
        }

        .error {
          position: absolute;
          bottom: -16px;
          left: 0;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #E25246;
          line-height: 17px;
        }
      }

      .tip {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #575a5a;
        line-height: 20px;

        i {
          cursor: pointer;
          transition: .2s;

          &:hover {
            color: $activeColor;
          }
        }

        span:last-child {
          color: $activeColor;
          cursor: pointer;
        }

      }

      .third {
        padding-top: 31px;
        border-top: 2px solid #DEDEDD;
        margin: 32px 0 0;

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #575a5a;
          line-height: 20px;
        }

        ul {
          @include flex(center, '');

          li {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #575a5a;
            line-height: 20px;
            margin-left: 24px;
            cursor: pointer;
            @include flex(center, '');

            &:first-child {
              margin-left: 0;
            }

            img {
              margin-right: 8px;
              width: 24px;
              height: 24px;
              border: none;
              margin-left: 0;
              border-radius: 0;
            }
          }
        }
      }
    }

    .info {
      padding: 125px 48px 0;
      text-align: center;

      .title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2020;
        line-height: 22px;

        span {
          color: $activeColor;
        }
      }

      img {
        width: 144px;
        height: 144px;
        margin-top: 32px;
      }

      .tip {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #afb0b0;
        line-height: 17px;
        margin-top: 120px;
        text-align: left;
      }
    }
  }
}
</style>
